<template>
  <section class="section statement-list">
    <div class="container">
      <b-message
        v-if="errorMessage"
        type="is-danger"
      >
        {{ errorMessage }}
      </b-message>
      <b-table
        :loading="loading"
        :data="owners"
        :empty="!loading && !owners.length && !errorMessage"
        default-sort="first_name"
        default-sort-direction="desc"
      >
        <b-table-column
          v-slot="props"
          field="last_name"
          label="Name"
          sortable
        >
          {{ props.row.first_name }} {{ props.row.last_name }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="group_name"
          label="Group"
        >
          {{ props.row.group_name }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="email"
          label="Email"
        >
          {{ props.row.email }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Statements"
        >
          <router-link
            :to="`/statements/${props.row.id}`"
            class="view-link button is-info"
            tag="button"
          >
            View
          </router-link>
        </b-table-column>

        <template #empty>
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>No owners found.</p>
            </div>
          </section>
        </template>
      </b-table>
    </div>
  </section>
</template>
<script>
import Raven from 'raven-js'
import { mapGetters } from 'vuex'
import { getErrorMessage } from '@/services/helpers'
export default {
  name: 'StatementGroups',

  data () {
    return {
      errorMessage: null,
      loading: true,
      selected: null
    }
  },

  computed: mapGetters({ owners: 'ownerGroups' }),

  async created () {
    try {
      await this.$store.dispatch('getOwnerGroups')
    } catch (e) {
      Raven.captureException(e)
      this.errorMessage = getErrorMessage(e)
    }
    if (this.owners.length === 1) {
      this.$router.push(`/statements/${this.owners[0].id}`)
    }
    this.loading = false
  }
}
</script>
