<template>
  <div>
    <button
      :class="{ 'is-loading': loading }"
      class="button is-info is-small"
      @click="downloadPdf"
    >
      <b-icon
        icon="file-pdf"
        size="is-small"
      />
      <span>Download PDF</span>
    </button>

    <b-message
      v-if="errorMessage"
      type="is-danger"
    >
      {{ errorMessage }}
    </b-message>
  </div>
</template>

<script>
import Raven from 'raven-js'
import { mapGetters } from 'vuex'
import ApiGateway from '@/services/api-gateway'
import { getErrorMessage } from '@/services/helpers'

export default {
  name: 'StatementDownloadButton',

  props: {
    id: {
      type: String,
      default: '' 
    },
    pdfKey: {
      type: String,
      default: '' 
    },
    ownerId: {
      type: String,
      default: '' 
    }
  },

  data () {
    return {
      loading: false,
      errorMessage: null
    }
  },

  computed: mapGetters([
    'activeCompanyId',
    'activeLinkedOwnerId'
  ]),

  methods: {
    async downloadPdf () {
      this.loading = true
      this.errorMessage = null
      try {
        const response = await ApiGateway.invokeApi({
          method: 'GET',
          pathTemplate: '/companies/{companyId}/owners/{userId}/statements/{ownerId}/{statementId}/{fileName}',
          params: {
            companyId: this.activeCompanyId,
            userId: this.activeLinkedOwnerId,
            ownerId: this.ownerId,
            statementId: this.id,
            fileName: this.pdfKey
          }
        })
        if (typeof response?.data?.url === 'string' && response.data.url.length > 0) {
          const link = document.createElement('a')
          link.href = response.data.url
          link.setAttribute('download', this.pdfKey ?? 'statement.pdf')
          link.setAttribute('target', '_blank')
          link.style.display = 'none'
          document.body.appendChild(link)
          link.click()
          setTimeout(() => {
            document.body.removeChild(link)
          }, 100)
        } else {
          throw new Error('Failed to download Owner Statement PDF.')
        }
      } catch (e) {
        Raven.captureException(e)
        this.errorMessage = getErrorMessage(e)
      }
      this.loading = false
    }
  }
}
</script>
