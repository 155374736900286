import * as types from '../mutation-types'
import ApiGateway from '@/services/api-gateway'

// Initial state
const state = {
  all: [],
  ownerGroups: [],
  hasYearEnd: false,
  currentOwnerGroup: {}
}

// Getters
const getters = {
  allStatements: state => state.all,
  hasYearEndStatement: state => state.hasYearEnd,
  ownerGroups: state => state.ownerGroups,
  currentOwnerGroup: state => state.currentOwnerGroup
}

// Actions
const actions = {
  async getAllStatements ({
    commit,
    rootGetters
  }, ownerId) {
    const response = await ApiGateway.invokeApi({
      method: 'GET',
      pathTemplate: '/companies/{companyId}/owners/{userId}/statements/{ownerId}',
      params: {
        companyId: rootGetters.activeCompanyId,
        userId: rootGetters.activeLinkedOwnerId,
        ownerId
      }
    })
    const statements = response.data
    commit(types.RECEIVE_STATEMENTS, { statements })
    commit(types.RECEIVE_CURRENT_OWNER_GROUP, { owner: state.ownerGroups.find(group => group.id === ownerId) })
  },
  async getOwnerGroups ({
    commit,
    rootGetters
  }) {
    const response = await ApiGateway.invokeApi({
      method: 'GET',
      pathTemplate: '/companies/{companyId}/owners/{userId}/owner-groups',
      params: {
        companyId: rootGetters.activeCompanyId,
        userId: rootGetters.activeLinkedOwnerId
      }
    })
    const ownerGroups = response.data
    commit(types.RECEIVE_OWNER_GROUPS, { ownerGroups })
  }
}

// Mutations
const mutations = {
  [types.RECEIVE_STATEMENTS] (state, { statements }) {
    state.all = statements.statements
    state.hasYearEnd = statements.hasYearEnd
  },
  [types.RECEIVE_OWNER_GROUPS] (state, { ownerGroups }) {
    state.ownerGroups = ownerGroups
  },
  [types.RECEIVE_CURRENT_OWNER_GROUP] (state, { owner }) {
    state.currentOwnerGroup = owner
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
